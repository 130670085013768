@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap);
body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #FBFBFB;
  color: #364456;
}

h1,h2,h3,h4,label,span,p,a {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 50px;   
  font-weight: 700;
}
h2 {
    font-size: 28px;   
    font-weight: 600; 
    letter-spacing: 0.03rem;
}
h3 {
  font-size: 35px;
  font-weight: 400;
  letter-spacing: 0.03rem;
}
h4 {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0.03rem;
}
label {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.33px;
}
span {
  font-size: 24px;
  font-weight: 300;
}

hr {
  width: 100%;
  border: 1px solid #fff; 
  opacity: 0.6;
}
.top {
  margin-top: 20px;
}
.bold {
  font-weight: 600;
}

.title{
  font-size: 26px;
}

.subtitle{
  font-size: 21px;
}

@media (max-width: 769px) {
  h1 {
    font-size: 18px;   
  }
  h2 {
      font-size: 16px;   
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
  label {
    font-size: 10px;
  }
  span {
    font-size: 12px;
  }
  .top {
    margin-top: 10px;
  }
  .title{
    font-size: 13px;
  }
  .subtitle{
    font-size: 11px;
  }
}

@media (max-width: 500px) {
  .top {
    margin-top: 0px;
  }
}
